.App {
  height: 100vh;
  width: 100vw;
  text-align: center;
  position: relative;
}

.backgroundImage {
  width: 100vw;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
}