header {
    align-items: center;
    display: flex;
    background-color: green;
    width: 100%;
    height: 75px;
}

#logo {
    width: 150px;
    margin-left: 5%;
}

nav ul {
    display: flex;
    justify-content: space-around;
}

nav li {
    list-style: none;
    margin: 0 0 0 2em;
}

nav li a {
    text-decoration: none;
    color: white;
    transition: 0.3s;
}

nav li a:hover {
    text-shadow: 0px 0px 5px white
}

