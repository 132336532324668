.History {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.listContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
}

.hist-entry {
    background-color: gainsboro;
    width: 400px;
    height: 300px;
    margin: 10px 0 10px 0;
}