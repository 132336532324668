.grid-container {
    width: 500px;
    position: relative;
    z-index: 2;
    padding-left: 10%;
}

.crop-grid {
    display: grid;
    grid-template-columns: auto auto auto auto auto;

}

.crop-layers {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-evenly;
    height: 500px;
    width: 500px;
}

.crop-layer {
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.crop-layer p {
    font-size: 22px;
}
  
.crop-box {
    width: 50px;
    height: 50px;
    margin: 5px;
    background-color: green;
}